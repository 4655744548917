//framework hooks
window.hasCustomStore = function (url, module) {
    // todo add logic here

    return false;
}
window.customStore = function (url, module, parentQs, customQs, params) {
    // todo add logic here

    return false;
}
window.customFormFieldChange = function (formField, event) {
    // todo add logic here

}
window.handleCustomValidation = function (detailsComponent, errorObj) {
    // todo add logic here

}

function getSelectedKeys() {
    var selection = getSelectedData();
    if (selection.length === 0) return [];
    return selection.map(x => x.ID);
}

function getSelectedData() {
    var grid = $("#datagridDx1Container").dxDataGrid("instance");
    if (grid === undefined) return [];
    return grid.getSelectedRowsData();
}

window.duplicateRecords = function () {
    var keys = getSelectedKeys();
    if (keys.length > 0) {
        $.ajax({
            url: '../api/admin/duplicaterecords?currentModule=' + currentModule,
            type: 'POST',
            data: JSON.stringify(keys),
            contentType: 'application/json',
            dataType: "json"
        }).done(function (result) {
            if (result.Error) {
                showMessage(result.Error, getWebAppTranslation("fe13"));
                return;
            } else {
                window.IlvoGrid.loadAndRenderData();
            }

        }).fail(function (xhr, textStatus, errorThrown) {
            showMessage(xhr.responseText, getWebAppTranslation("fe13"));
        }).always(function () {
            //hideLoadPanel();
        });
    }
}

window.listPageRendered = function (module) {
    if (module === "Mail" || module === "Other") {
        $("#btn-create").hide();
        $("#btn-edit").hide();
        $("#btn-delete").hide();
    }
    else {
        $("#btn-create").show();
        $("#btn-edit").show();
        $("#btn-delete").show();
    }
}

window.sideMenuReady = function () {
    $('#ilvo-side-menu p[webmodule="Mail"]').attr('custom-sidemenu-action', 'sendmail');
    $('#ilvo-side-menu p[webmodule="Mail"]').text('Send mail');
    $('#ilvo-side-menu p[webmodule="Mail"]').removeAttr('webmodule');
    $('#ilvo-side-menu p[custom-sidemenu-action="sendmail"]').on('click', function() {
        var sendMailUrl = window.location.origin + "/Mail/SendMail";
        window.location.href = sendMailUrl;
    });
}

//window.addCustomRegisterFields = function () {
//    // todo add logic here

//    //$('#d1-register-custom-container').append("<div id='d1-test'></div>");

//    //$("#d1-test").dxTextBox({
//    //    placeholder: "Testveldje",
//    //    name: "testfield",
//    //    height: 48
//    //});
//}


// framework overrides





//event handlers 

window.addEventListener("customAction-Communication-Actions", eventHandler0);

function eventHandler0(e, control) {
    function savePhone() {
        var form = $("#phone-popup").dxForm("instance");
        var formValidation = form.validate();

        if (formValidation.isValid) {
            $.ajax({
                url: '../api/project/savephonecall',
                type: 'POST',
                data: JSON.stringify(form.option('formData')),
                contentType: 'application/json',
                dataType: "json"
            }).done(function (result) {
                if (result.Error) {
                    $("#phone-popup-summary").dxValidationSummary('instance').option("items", [{ text: result.Error }]);
                } else {
                    $('#phone-popup-container').dxPopup('instance').hide();
                    window.IlvoGrid.loadAndRenderData();
                }
            });
        }
    }

    function saveStatus() {
        var form = $("#status-popup").dxForm("instance");
        var formValidation = form.validate();

        if (formValidation.isValid) {
            $.ajax({
                url: '../api/project/savestatus',
                type: 'POST',
                data: JSON.stringify(form.option('formData')),
                contentType: 'application/json',
                dataType: "json"
            }).done(function (result) {
                if (result.Error) {
                    $("#status-popup-summary").dxValidationSummary('instance').option("items", [{ text: result.Error }]);
                } else {
                    $('#status-popup-container').dxPopup('instance').hide();
                    window.IlvoGrid.loadAndRenderData();
                }
            });
        }
    }

    function showPhonePopup(newPhone) {

        if ($('#phone-popup-container').length === 0) {
            $('#appContent').append('<div id="phone-popup-container"></div>');
        }

        const phonePopupContentTemplate = function () {
            return $('<div>').append($(`<div id="phone-popup"></div><div id="phone-popup-summary"></div>`));
        };

        var url = '../api/project/getphonepopupdata';

        if (!newPhone) {
            var selectedItems = $("#datagridDx1Container").dxDataGrid("instance").getSelectedRowsData();
            url += '?id=' + selectedItems[0].ID;
        }

        var title = "Add";

        if (!newPhone) {
            title = "Edit";
        }

        $.ajax({
            url: url,
            type: 'GET',
            contentType: 'application/json',
            dataType: "json"
        }).done(function (result) {
            var res = result.Result;
            console.log(res);

            const phonePopup = $('#phone-popup-container').dxPopup({
                contentTemplate: phonePopupContentTemplate,
                width: 550,
                height: 650,
                container: 'document',
                showTitle: true,
                title: title + ' communication',
                visible: false,
                dragEnabled: true,
                closeOnOutsideClick: false,
                showCloseButton: false,
                position: 'center',
                shading: true,
                //shadingColor: 'rgba(245, 40, 145, 0)',
                toolbarItems:
                    [
                        {
                            widget: 'dxButton',
                            toolbar: 'bottom',
                            location: 'after',
                            options: {
                                text: 'Save',
                                onClick() {
                                    savePhone();

                                },
                            },
                        },
                        {
                            widget: 'dxButton',
                            toolbar: 'bottom',
                            location: 'after',
                            options: {
                                text: 'Close',
                                onClick() {
                                    phonePopup.hide();
                                },
                            },
                        }
                    ],
                onShown: function (e) {

                    const form = $('#phone-popup').dxForm({
                        labelMode: 'floating',
                        validationGroup: 'phone-group',
                        //colCount: 2,
                        formData: res,
                        items: [
                            {
                                dataField: 'id',
                                editorType: 'dxTextBox',
                                visible: false,
                            },
                            {
                                dataField: 'date',
                                label: { text: 'Date' },
                                editorType: 'dxDateBox',
                                isRequired: true,
                                editorOptions: {
                                    type: 'datetime',
                                    displayFormat: "EEEE, dd MMM yyyy HH:mm",
                                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                                    calendarOptions: {
                                        firstDayOfWeek: 1
                                    },
                                    showClearButton: true
                                },
                            },
                            {
                                dataField: 'currentUserId',
                                label: { text: 'User' },
                                editorType: 'dxSelectBox',
                                isRequired: false,
                                editorOptions: {
                                    displayExpr: 'Display',
                                    valueExpr: 'ID',
                                    dataSource: res.users,
                                    searchEnabled: true,
                                    searchTimeout: 100,
                                }
                            },
                            //{
                            //    dataField: 'phoneNumber',
                            //    label: { text: 'Phone number' },
                            //    editorType: 'dxTextBox',
                            //    editorOptions: {
                            //        showClearButton: true,
                            //        maxLength: 50,
                            //    },
                            //},
                            {
                                dataField: 'comTypeId',
                                label: { text: 'Communication type' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    displayExpr: 'Display',
                                    valueExpr: 'ID',
                                    dataSource: res.comTypes,
                                    searchEnabled: true,
                                    searchTimeout: 100,
                                }
                            },
                            {
                                dataField: 'subject',
                                label: { text: 'Subject' },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                    showClearButton: true,
                                    maxLength: 255,
                                },
                            },
                            {
                                dataField: 'message',
                                label: { text: 'Message' },
                                editorType: 'dxTextArea',
                                editorOptions: {
                                    showClearButton: true,
                                    height: 225,
                                },
                            },
                            {
                                dataField: 'comStatusId',
                                label: { text: 'Communication status' },
                                isRequired: true,
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    displayExpr: 'Display',
                                    valueExpr: 'ID',
                                    dataSource: res.comStatuses,
                                    searchEnabled: true,
                                    searchTimeout: 100,
                                }
                            },
                        ],
                        readOnly: false,
                        showColonAfterLabel: false,
                        labelLocation: 'left',
                        //minColWidth: 300,
                        //colCount: 2,
                    }).dxForm('instance');

                    $("#phone-popup-summary").dxValidationSummary({ validationGroup: "phone-group" }).dxValidationSummary("instance");

                }
            }).dxPopup('instance');

            phonePopup.show();
        });



    }

    function showStatusPopup() {

        if ($('#status-popup-container').length === 0) {
            $('#appContent').append('<div id="status-popup-container"></div>');
        }

        const statusPopupContentTemplate = function () {
            return $('<div>').append($(`<div id="status-popup"></div><div id="status-popup-summary"></div>`));
        };

        var url = '../api/project/getstatuspopupdata?ids=';

        var selectedItems = $("#datagridDx1Container").dxDataGrid("instance").getSelectedRowsData();
        for (var i = 0; i < selectedItems.length; i++) {
            url += selectedItems[i].ID + ",";
        }

        $.ajax({
            url: url,
            type: 'GET',
            contentType: 'application/json',
            dataType: "json"
        }).done(function (result) {
            var res = result.Result;

            const statusPopup = $('#status-popup-container').dxPopup({
                contentTemplate: statusPopupContentTemplate,
                width: 550,
                height: 250,
                container: 'document',
                showTitle: true,
                title: 'Change status',
                visible: false,
                dragEnabled: true,
                closeOnOutsideClick: false,
                showCloseButton: false,
                position: 'center',
                shading: true,
                //shadingColor: 'rgba(245, 40, 145, 0)',
                toolbarItems:
                    [
                        {
                            widget: 'dxButton',
                            toolbar: 'bottom',
                            location: 'after',
                            options: {
                                text: 'Save',
                                onClick() {
                                    saveStatus();

                                },
                            },
                        },
                        {
                            widget: 'dxButton',
                            toolbar: 'bottom',
                            location: 'after',
                            options: {
                                text: 'Close',
                                onClick() {
                                    statusPopup.hide();
                                },
                            },
                        }
                    ],
                onShown: function (e) {

                    const form = $('#status-popup').dxForm({
                        labelMode: 'floating',
                        validationGroup: 'status-group',
                        //colCount: 2,
                        formData: res,
                        items: [
                            {
                                dataField: 'ids',
                                editorType: 'dxTextBox',
                                visible: false,
                            },
                            {
                                dataField: 'comStatusId',
                                label: { text: 'Communication status' },
                                isRequired: true,
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    displayExpr: 'Display',
                                    valueExpr: 'ID',
                                    dataSource: res.comStatuses,
                                    searchEnabled: true,
                                    searchTimeout: 100,
                                }
                            },
                            {
                                dataField: 'module',
                                editorType: 'dxTextBox',
                                visible: false,
                                editorOptions: {
                                    value: currentModule
                                }
                            }
                        ],
                        readOnly: false,
                        showColonAfterLabel: false,
                        labelLocation: 'left',
                        //minColWidth: 300,
                        //colCount: 2,
                    }).dxForm('instance');

                    $("#status-popup-summary").dxValidationSummary({ validationGroup: "status-group" }).dxValidationSummary("instance");

                }
            }).dxPopup('instance');

            statusPopup.show();
        });



    }


    var height = 222;

    var selectedItems = $("#datagridDx1Container").dxDataGrid("instance").getSelectedRowsData();

    if (selectedItems.length > 0) {
        height += 52; // space for change status
    }

    if (selectedItems.length === 1) {
        height += 52; // either for edit phone call or reply mail
    }

    console.log(selectedItems);


    //var res = result.Result;
    if ($('#popup-container').length === 0) {
        $('#appContent').append('<div id="popup-container"></div>');
    }

    const popupContentTemplate = function () {
        return $('<div>').append($(`<div id="communication-actions"><p><div id="new-mail"></div></p><p><div id="view-conversation"></div></p><p><div id="reply-mail"></div></p><p><div id="add-phone"></div></p><p><div id="edit-phone"></div></p><p><div id="change-status"></div></p></div>`));
    };
    const popup = $('#popup-container').dxPopup({
        contentTemplate: popupContentTemplate,
        width: 300,
        height: height,
        container: 'document',
        showTitle: true,
        title: 'Communication Actions',
        visible: false,
        dragEnabled: true,
        closeOnOutsideClick: false,
        showCloseButton: false,
        position: 'center',
        shading: true,
        //shadingColor: 'rgba(245, 40, 145, 0)',
        toolbarItems: [

            {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                    text: 'Close',
                    onClick() {
                        popup.hide();
                    },
                },
            }],
        onShown: function (e) {

            if (currentModule === "Mail") {
                $('#new-mail').dxButton({
                    stylingMode: 'contained',
                    text: 'New email',
                    type: 'default',
                    width: 400,
                    onClick() {
                        window.location.href = '/Mail/SendMail';
                    },
                });
            }

            //if (selectedItems.length === 1 && selectedItems[0].CommunicationTypeID === "Email") {
            //    $('#reply-mail').dxButton({
            //        stylingMode: 'contained',
            //        text: 'Reply email',
            //        type: 'default',
            //        width: 400,
            //        onClick() {
            //            DevExpress.ui.notify('work in progress');
            //        },
            //    });
            //}

            if (selectedItems.length === 1 && selectedItems[0].CommunicationTypeID === "Email") {
                $('#reply-mail').dxButton({
                    stylingMode: 'contained',
                    text: 'View conversation',
                    type: 'default',
                    width: 400,
                    onClick() {
                        window.location.href = '/Mail/Detail/' + selectedItems[0].ID
                    },
                });
            }

            if (currentModule === "Other") {
                $('#add-phone').dxButton({
                    stylingMode: 'contained',
                    text: 'Add communication',
                    type: 'default',
                    width: 400,
                    onClick() {
                        popup.hide();
                        showPhonePopup(true);
                    },
                });
            }

            if (selectedItems.length === 1 && selectedItems[0].CommunicationTypeID !== "Email") {
                $('#edit-phone').dxButton({
                    stylingMode: 'contained',
                    text: 'Edit communication',
                    type: 'default',
                    width: 400,
                    onClick() {
                        popup.hide();
                        showPhonePopup(false);
                    },
                });
            }

            if (selectedItems.length > 0) {
                $('#change-status').dxButton({
                    stylingMode: 'contained',
                    text: 'Change status',
                    type: 'default',
                    width: 400,
                    onClick() {
                        popup.hide();
                        showStatusPopup();
                    },
                });
            }
        }
    }).dxPopup('instance');

    popup.show();



}


window.addEventListener("customAction-NewMail", eventHandler1);
function eventHandler1(e, control) {
    window.location.href = '/Mail/SendMail';
}

//Communication Module
//VARIABLES
var mails, emailList, groupToExpand, tagBoxId, mailID, action, replyList;
var btnCcPlus, btnCcMinus, btnBccPlus, btnBccMinus;
var divCc, divBcc;
var mailsToCopy, clipboardMessage = "", toastType;

// Init jQuery
$(() => {
    //bind html to js vars
    divCc = $("#divCC");
    divBcc = $("#divBCC");

    btnCcPlus = $("#btnCcPlus");
    btnCcMinus = $("#btnCcMinus");
    btnBccPlus = $("#btnBccPlus");
    btnBccMinus = $("#btnBccMinus");

    action = $("#action").val();

    //on document load hide minus buttons
    btnCcMinus.hide();
    btnBccMinus.hide();

    //on document load hide divs for CC and BCC
    divCc.hide();
    divBcc.hide();

    //on document load, get user emails
    loadUserEmails();

    btnCcPlus.click(function () {
        divCc.show();
        btnCcMinus.show();
        btnCcPlus.hide();
    });

    btnCcMinus.click(function () {
        divCc.hide();
        divBcc.hide();
        btnCcPlus.show();
        btnCcMinus.hide();
        btnBccPlus.show();
        btnBccMinus.hide();
    });

    btnBccPlus.click(function () {
        divBcc.show();
        btnBccMinus.show();
        btnBccPlus.hide();
    });

    btnBccMinus.click(function () {
        divBcc.hide();
        btnBccPlus.show();
        btnBccMinus.hide();
    })

    const htmlEditor = $("#html-editor").dxHtmlEditor({
        height: "100%",
        name: "Body",
        valueType: "html",
        onContentReady: function (e) {
            e.component.format("font", "Arial");
            e.component.format("size", "12pt");
            e.component.getQuillInstance().blur();
            e.component.getQuillInstance().focus();
        },
        toolbar: {
            items: [
                "undo", "redo",
                "separator",
                {
                    name: "size",
                    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                    options: { value: '12pt' }
                },
                {
                    name: "font",
                    acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
                    options: { value: 'Arial' }
                },
                "separator",
                "bold", "italic", "strike", "underline",
                "separator",
                "alignLeft", "alignCenter", "alignRight", "alignJustify",
                "separator",
                "orderedList", "bulletList",
                "separator",
                {
                    name: "header",
                    acceptedValues: [false, 1, 2, 3, 4, 5]
                },
                "separator",
                "clear",
                "separator",
            ],
            options: {
                handlers: {
                    // ...
                    'hr': function (value) {
                        this.quill.format('hr', true);
                    }
                }

            },
            multiline: true
        }
    });

    const fileUploader = $('#file-uploader').dxFileUploader({
        selectButtonText: 'Add attachment',
        name: 'Attachments',
        labelText: '',
        accept: '',
        multiple: true,
        uploadMode: 'useForm'
    });

    //AJAX calls
    function loadUserEmails() {
        $.ajax({
            url: "/Mail/GetRecipients",
            async: false
        }).done(function (result) {
            mails = new DevExpress.data.DataSource({
                store: {
                    type: "array",
                    data: result
                },
                group: [{getter: "Group", desc: false}]
            });
            console.log(mails);
            createTagboxes("#selectToMails", "EmailTo", "Choose recipient(s)");
            createTagboxes("#selectCcMails", "EmailCC", "Select optional");
            createTagboxes("#selectBccMails", "EmailBCC", "Select optional");
        });
    }

    function getEmailsByGroup(groupName) {
        $.ajax({
            url: "/Mail/GetGroupRecipients",
            data: {
                groupName
            },
            async: false
        }).done(function (result) {
            emailList = result;
        });
    }

    function getReplyRecipients(id) {
        $.ajax({
            url: "/Mail/GetReplyRecipients",
            data: { id },
            async: false
        }).done(function (result) {
            replyList = result;
        })
    }

    function expandGroup(event, id) {
        $(id).dxTagBox('instance').close();
        $(id).dxTagBox('instance').option("disabled", true);

        console.log(event);

        tagBoxId = id;
        groupToExpand = event.Code;

        getEmailsByGroup(groupToExpand);

        createPopup(emailList, groupToExpand);
    }

    function createTagboxes(id, name, placeholder) {
        $(id).dxTagBox({
            name: name,
            valueExpr: "Code",
            displayExpr: "Code",
            dataSource: mails,
            grouped: true,
            height: 40,
            hideSelectedItems: true,
            searchEnabled: true,
            showSelectionControls: true,
            showClearButton: true,
            placeholder: placeholder,
            acceptCustomValue: true,
            multiline: false,
            tagTemplate: function (tagData) {
                if (tagData.IsGroup) {
                    return $("<div />")
                        .addClass("dx-tag-content")
                        .append(
                            $("<span style='margin-left:17px;' />").text(tagData.Code),
                            $("<div />").addClass("dx-tag-remove-button"),
                            $("<div />").addClass("dx-tag-add-button").click(function () { expandGroup(tagData, id) })
                        );
                }
                else {
                    return $("<div />")
                        .addClass("dx-tag-content")
                        .append(
                            $("<span />").text(tagData.Code),
                            $("<div />").addClass("dx-tag-remove-button")
                        );
                }
            },
            onCustomItemCreating(args) {
                const newValue = { Code: args.text, IsGroup: false };
                const { component } = args;
                const currentItems = component.option('dataSource');
                console.log(currentItems);
                currentItems.unshift(newValue);
                component.option('dataSource', currentItems);
                args.customItem = newValue;
            }
        });
    }

    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = "<style>p { margin: 0px; }</style><br /><code>_</code><p></p>" + html;
        return txt.value;
    }

    const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const popup = $("#popupContainer").dxPopup({
        resizeEnabled: true,
        dragEnabled: false,
        closeOnOutsideClick: false,
        showCloseButton: false,
        width: 500,
        shading: true,
        onHiding(e) {
            $(tagBoxId).dxTagBox('instance').option("disabled", false);
        }
    }).dxPopup("instance");

    //create content for popup dynamically
    function createPopup(emailList, groupCode) {
        const content = $("<div />");
        
        if (emailList.length > 0) {
            content.append(
                $("<strong />").text("Expanding this group will return following list:"),
                $("<ul>")
            );

            for (let i = 0; i < emailList.length; i++) {
                content.append($("<li />").text(emailList[i].Code));
            }

            content.append(
                $("</ul><br />"),
                $("<p />").text("Are you sure you want to expand?")
            );

            popup.option('toolbarItems', yesNoButtonsPopup);
        }
        else {
            content.append($("<p />").text("There are no mailaddresses for this group!"));

            popup.option('toolbarItems', okButtonPopup);
        }

        popup.option('title', 'Expand group: ' + groupCode);
        popup.option('contentTemplate', content);
        popup.show();
    }

    const yesNoButtonsPopup = [{
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'center',
        options: {
            text: 'Yes',
            onClick() {
                let tagBoxValues = $(tagBoxId).dxTagBox('instance').option('value');

                tagBoxValues = tagBoxValues.filter(item => item !== groupToExpand);

                for (let i = 0; i < emailList.length; i++) {
                    tagBoxValues.push(emailList[i].Code);
                }

                const uniqueValues = tagBoxValues.filter(distinct).sort();

                $(tagBoxId).dxTagBox('instance').option('value', uniqueValues);

                popup.hide();
            }
        }
    }, {
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'center',
        options: {
            text: 'No',
            onClick() {
                popup.hide();
            }
        }
    }];

    const okButtonPopup = [{
        widget: 'dxButton',
        toolbar: 'bottom',
        location: 'center',
        options: {
            text: 'OK',
            onClick() {
                popup.hide();
            }
        }
    }];

    mailID = $("#mailId").val();

    $("#popupButton").click(function () {
        //popup.show();
    })

    $("#backButton").click(function () {
        window.location.href = '/';
    })

    $("#replyButton").click(function () {
        window.location.href = '/Mail/ReplyToMail/' + mailID;
    })

    $("#backReplyButton").click(function () {
        window.location.href = '/Mail/Detail/' + mailID;
    })

    if (action == "") {
        $("#html-editor").dxHtmlEditor("instance").option('value', decodeHtml(body), null);
        $('#html-editor > div.dx-quill-container.ql-container > div > p > code').parent().html('<p></p><p>Best regards,</p><p></p><p>RCG\'s Secretariat - Stakeholders database</p><p></p><img src="'+ window.location.origin +'/project/img/logo_RCG_SECWEB.png" width="400"/>');
    }

    if (action === "Reply") {
        getReplyRecipients(mailID);

        $("#selectToMails").dxTagBox('instance').option('value', replyList);
        $("#html-editor").dxHtmlEditor("instance").option('value', decodeHtml(body), null);
        $('#html-editor > div.dx-quill-container.ql-container > div > p > code').parent().html('<p style="border-bottom: 1px solid lightgrey;"></p><p></p>');
    }

    $("#copyToClip").click(function () {
        mailsToCopy = "";

        let toMails = $("#selectToMails").dxTagBox("instance").option("selectedItems");

        if (toMails.length < 1) {
            toastType = "warning";
            clipboardMessage = "There is nothing to copy!";
        }
        else {
            toMails.forEach((item) => {
                mailsToCopy += item.Code + "; ";
            })
            navigator.clipboard.writeText(mailsToCopy);

            toastType = "success";
            clipboardMessage = "Copied to clipboard.";
        }

        DevExpress.ui.notify(
            {
                message: clipboardMessage,
                width: 220,
                position: {
                    my: "top",
                    at: "bottom",
                    offset: "-11 10",
                    of: "#copyToClip"
                },
                animation: {
                    show: {
                        type: "fade",
                        duration: 750,
                        from: 0,
                        to: 1
                    },
                    hide: {
                        type: "fade",
                        duration: 750,
                        from: 1,
                        to: 0
                    }
                }
            },
            toastType,
            1000
        );
    })
});